import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { uniqueId } from "lodash"
import { Button, Col, PageHeader, Row } from "antd"
import HTMLReactParser from "react-html-parser"
import { FilePdfOutlined } from "@ant-design/icons"

import SiteLayout from "../components/site-layout"
import Seo from "../components/seo"
import { getContent } from "../utils/selectors"
import SocialLinkButton from "../components/social-link-button"

import "../styles/contact.less"

const ContactPage = ({ location, data }) => {
  /**
   * VARS
   */
  const content = getContent(data)

  const socialLinks = content.find(item => item.tagName === "ul")
  const contactBlurb = content.find(item => item.tagName === "p")
  const links = HTMLReactParser(socialLinks?.innerHtml)

  /**
   * RENDER
   */
  return (
    <SiteLayout location={location}>
      <Seo title="Contact" />
      <PageHeader className="content-page-header" title="Contact">
        <Row gutter={16}>
          <Col span={12}>
            <p dangerouslySetInnerHTML={{ __html: contactBlurb?.innerHtml }} />
            <div>
              <Button type="link" href="resume.pdf" target="_blank">
                <FilePdfOutlined />
                Resume
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div className="social-link-buttons">
              {links.map(link => (
                <Fragment key={uniqueId("button")}>
                  <SocialLinkButton link={link} />
                </Fragment>
              ))}
            </div>
          </Col>
        </Row>
      </PageHeader>
    </SiteLayout>
  )
}

/**
 * GRAPHQL
 */
export const query = graphql`
  query {
    allWpPage(filter: { slug: { eq: "contact" } }) {
      edges {
        node {
          blocks {
            innerHtml
            tagName
          }
        }
      }
    }
  }
`

export default ContactPage
