import React from "react"
import { Button } from "antd"
import SocialIcons from "./social-icons"

const SocialLinkButton = ({ link }) => {
  const href = link?.props?.children[0]?.props?.href
  const label = link?.props?.children[0]?.props?.children[0]

  return (
    <Button type="link" href={href} target="_blank">
      <SocialIcons label={label} />
      {label}
    </Button>
  )
}

export default SocialLinkButton
