import React from "react"
import {
  CodeOutlined,
  CodeSandboxOutlined,
  CommentOutlined,
  GithubOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons"

const SocialIcons = ({ label, style }) => {
  if (label === "LinkedIn") return <LinkedinOutlined style={style} />
  if (label === "Twitter") return <TwitterOutlined style={style} />
  if (label === "GitHub") return <GithubOutlined style={style} />
  if (label === "CodeSandbox") return <CodeSandboxOutlined style={style} />
  if (label === "Bitbucket") return <CodeOutlined style={style} />
  if (label === "Mastodon") return <CommentOutlined style={style} />
  return null
}

export default SocialIcons
